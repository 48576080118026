<template>
  <div>
    <el-card>
      <div class="demo-drawer__content">
        <el-form ref="formRef" :model="form" :rules="rules">
          <el-form-item label="类型" :label-width="80" prop="type">
            <el-radio-group v-model="form.type">
              <el-radio :label="'SELF'">自建文章</el-radio>
              <el-radio :label="'WECHAT'">微信公众号</el-radio>
            </el-radio-group>
          </el-form-item>

          <template v-if="form.type == 'SELF'">
            <el-form-item label="选择文章" :label-width="80" prop="jumpUrl">
              <el-button size="large" style="width: 200px" type="primary" @click="chooseWexinArticle()">
                选择公众号文章
              </el-button>
            </el-form-item>

            <el-form-item label="文章内容" :label-width="80">
              <Rich :htmlValue="form.content" ref="Editor" @contentChange="contentChange" />
            </el-form-item>
          </template>

          <template v-if="form.type == 'WECHAT'">
            <el-form-item label="文章链接" :label-width="80" prop="linkUrl">
              <el-input v-model="form.linkUrl" placeholder="请输入公众号文章链接"></el-input>
            </el-form-item>

          </template>

          <el-form-item align="center">
            <el-button size="large" style="width: 200px" type="info" plain @click="goBack">返回</el-button>
            <el-button size="large" style="width: 200px" type="primary" @click="submitInfo()">
              提交
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>

    <vab-upload ref="vabUploadRef" :limit="1" name="file" :size="2" url="/upload" @addImg="handleAddImg" />

    <el-dialog v-model="dialogVisible" append-to-body title="查看大图">
      <div>
        <el-image :src="dialogImageUrl" />
      </div>
    </el-dialog>

    <el-dialog v-model="articleVisible" title="选择文章">
      <el-row>
        <el-col v-for="(item, index) in wexinArticleList" :key="index" :offset="1" :span="5">
          <el-card :body-style="{ padding: '0px' }">
            <el-image :fit="'contain'" :src="item.thumbUrl" />
            <div style="padding: 14px">
              <div>{{ item.name }}</div>
              <el-button style="width: 100px; margin-top: 10px" type="primary" @click="chooseWexinArticleItem(item.articleId)">
                选中
              </el-button>
            </div>
          </el-card>
        </el-col>
      </el-row>

      <el-pagination background :current-page="queryForm.pageNum" :layout="layout" :page-size="queryForm.pageSize" :total="total" @current-change="handleCurrentChange" @size-change="handleSizeChange" />
    </el-dialog>
  </div>
</template>

<script>
import {
  defineComponent,
  getCurrentInstance,
  onActivated,
  onMounted,
  reactive,
  toRefs,
  shallowRef,
  ref,
} from 'vue'
import { upLoadImg, getLabelLabel, editActiveTicket } from '@/api/active'
import {
  getNewsContentDetail,
  newsContentSave,
  getWexinArticle,
  getWeixinArticleDetail,
} from '@/api/content'
import { parseTime } from '@/utils/index'
import VabQuill from '@/extra/VabQuill'
import VabUpload from '@/extra/VabUpload'
import Rich from '@/components/rich/index'

export default defineComponent({
  name: 'ArticleEdit',
  components: {
    VabQuill,
    VabUpload,
    Rich,
  },
  setup(props, { emit }) {
    // 编辑器初始化

    const { proxy } = getCurrentInstance()
    const state = reactive({
      mode: 'default', // 或 'simple'
      dialogVisible: false,
      articleVisible: false,
      layout: 'prev, pager, next',
      total: 0,
      dialogImageUrl: '',
      formRef: null,
      vabUploadRef: null,
      editorRef: null,
      checkMenu: [],
      bannarList: [],
      size: '50%',
      form: {
        content: ``,
        type: `SELF`,
      },
      rules: {
        linkUrl: [
          { required: true, trigger: 'blur', message: '请输入文章链接' },
        ],
        coverUrl: [
          { required: true, trigger: 'change', message: '请选择封面' },
        ],
        bannerUrl: [
          { required: true, trigger: 'change', message: '请选择海报' },
        ],
        status: [{ required: true, trigger: 'change', message: '请选择状态' }],
        tagIds: [
          { required: true, trigger: 'change', message: '请选择文章标签' },
        ],
        content: [
          { required: true, trigger: 'blur', message: '请输入文章内容' },
        ],
        articleCategoryId: [
          { required: true, trigger: 'blur', message: '请选择文章类别' },
        ],
      },
      title: '',
      list: [],
      pickerStartDate: null,
      limit: 1,
      typeOption: [],
      tagOption: [],
      articleId: 'new',
      queryForm: { pageSize: 8, pageNum: 1, appKey: 'WX_PUBLIC_WEMEET' },
      wexinArticleList: [],
    })

    const showEdit = (row) => {
      state.title = '配置'
    }

    const handleData = (arr, checkArr) => {
      for (let i in arr) {
        arr[i] = String(arr[i])
      }
      return arr
    }

    const fetchData = async () => {
      let newsCode = proxy.$route.query.newsCode
        ? proxy.$route.query.newsCode
        : ''
      state.form.newsCode = newsCode

      const { data: content } = await getNewsContentDetail(newsCode)
      console.log(content)
      state.form.content = content.content ? content.content : ''
      state.form.type = content.contentType ? content.contentType : 'SELF'
      state.form.linkUrl = content.linkUrl ? content.linkUrl : ''

      const { data } = await getWexinArticle(state.queryForm)
      state.wexinArticleList = handleDataWexin(data.item)
      console.log(state.wexinArticleList)
    }

    onMounted(() => {
      fetchData()
    })
    // 图片相关接口
    // 上传海报
    const upCover = async (e) => {
      let upData = new FormData()
      upData.append('file', e.file)
      const { data } = await upLoadImg(upData)
      state.form.coverUrl = data
    }

    const upBannar = async (e) => {
      let upData = new FormData()
      upData.append('file', e.file)
      const { data } = await upLoadImg(upData)
      state.form.bannerUrl = data
    }

    const handleError = (err, file) => {
      proxy.$baseMessage(
        `文件[${file.raw.name}]上传失败,文件大小为${_.round(
          file.raw.size / 1024,
          0
        )}KB`,
        'error',
        'vab-hey-message-error'
      )
    }

    const handleExceed = (files) => {
      proxy.$baseMessage(
        `当前限制选择 ${proxy.limit} 个文件，本次选择了
               ${files.length}
               个文件`,
        'error',
        'vab-hey-message-error'
      )
    }

    const handleExceed1 = (files) => {
      proxy.$baseMessage(
        `当前限制选择 ${proxy.limit} 个文件，本次选择了
               ${files.length}
               个文件`,
        'error',
        'vab-hey-message-error'
      )
    }

    const handleRemove = () => {
      state.form.coverUrl = ''
    }
    const handleRemove1 = () => {
      state.form.bannerUrl = ''
    }

    const handlePreview = (file) => {
      state.dialogImageUrl = file.url
      state.dialogVisible = true
    }

    // 富文本上传图片
    const handleAddImg = async (e) => {
      console.log(state.form.content, '-=======>>>')
      state.form.content += `<img src="${e}" />`
      console.log(state.form.content, '-=======>>>')
    }
    const submitInfo = () => {
      console.log(state.form)
      state['formRef'].validate(async (valid) => {
        if (valid) {
          await newsContentSave(state.form)
          proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
          // proxy.$pub('reload-router-view')
          // proxy.$router.replace({ path: '/contentCenter/contentManage' })
          proxy.$router.go(-1)
        }
      })
    }

    const goBack = () => {
      proxy.$router.go(-1)
    }
    const handleDataWexin = (data) => {
      let nData = []
      if (!data || data.length == 0) return false
      data.forEach((item) => {
        let obj = {
          articleId: item.article_id,
          thumbUrl: item.content.news_item[0].thumb_url,
          name: item.content.news_item[0].title,
        }
        nData.push(obj)
      })
      return nData
    }
    // 选择公众号文章
    const chooseWexinArticle = async () => {
      state.articleVisible = true
    }

    const handleSizeChange = async (val) => {
      state.queryForm.pageSize = val
      const { data } = await getWexinArticle(state.queryForm)
      state.wexinArticleList = handleDataWexin(data.item)
    }
    const handleCurrentChange = async (val) => {
      state.queryForm.pageNum = val
      const { data } = await getWexinArticle(state.queryForm)
      state.wexinArticleList = handleDataWexin(data.item)
    }

    const chooseWexinArticleItem = async (articleId) => {
      const { data } = await getWeixinArticleDetail({
        articleId,
        appKey: 'WX_PUBLIC_WEMEET',
      })
      state.form.content = data[0].content
      state.articleVisible = false
    }

    const contentChange = (value) => {
      state.form.content = value
    }

    return {
      ...toRefs(state),
      showEdit,
      contentChange,
      upBannar,
      fetchData,
      handleAddImg,
      submitInfo,
      handleError,
      handleExceed,
      handleExceed1,
      handleRemove,
      handleRemove1,
      handlePreview,
      chooseWexinArticle,
      handleSizeChange,
      handleCurrentChange,
      chooseWexinArticleItem,
      upCover,
      goBack,
    }
  },
})
</script>

<style lang="scss" scoped>
.vab-tree-border {
  height: 200px;
  padding: $base-padding;
  overflow-y: auto;
  border: 1px solid #dcdfe6;
  border-radius: $base-border-radius;
}

.demo-drawer__content {
  // height: 85vh;
  overflow: auto;
}
.demo-drawer__footer {
  position: fixed;
  right: 20px;
  bottom: 10px;
}
</style>

<style lang="scss"></style>
